@import 'swiper/css/bundle';
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Global variables. */
:root {

  /* Default colors; will be overridden by cms theme settings */
  --background: #FFFFFF;
  --text: #29304E;
  --border: #D0D1D2;

  --primary: #29304E;
  --secondary: #BF2033;
  --accent1: #005047;
  --accent2: #ECE7D9;

  --black: #000000;
  --off-black: #29282D;
  --dark-gray: #4e5055;
  --medium-dark-gray: #707070;
  --medium-gray: #7E7F81;
  --gray: #D0D1D2;
  --light-gray: #E0E0E0;
  --off-white: #fafafa;
  --white: #FFFFFF;

  --primary-btn-bg-color: #29304E;
  --primary-btn-border-color: #29304E;
  --primary-btn-text-color: #FFFFFF;
  --primary-btn-hover-bg-color: #251E3C;
  --primary-btn-hover-border-color: #251E3C;
  --primary-btn-hover-text-color: #FFFFFF;

  --secondary-btn-bg-color: #F6F6F6;
  --secondary-btn-border-color: #29304E;
  --secondary-btn-text-color: #29304E;
  --secondary-btn-hover-bg-color: #29304E;
  --secondary-btn-hover-border-color: #29304E;
  --secondary-btn-hover-text-color: #F6F6F6;

  --inverse-light-btn-bg-color: transparent;
  --inverse-light-btn-border-color: #FFFFFF;
  --inverse-light-btn-text-color: #FFFFFF;
  --inverse-light-btn-hover-bg-color: #FFFFFF;
  --inverse-light-btn-hover-border-color: #FFFFFF;
  --inverse-light-btn-hover-text-color: #29304E;

  --inverse-dark-btn-bg-color: rgba(255, 255, 255, 0.5);
  --inverse-dark-btn-border-color: #29304E;
  --inverse-dark-btn-text-color: #29304E;
  --inverse-dark-btn-hover-bg-color: #29304E;
  --inverse-dark-btn-hover-border-color: #29304E;
  --inverse-dark-btn-hover-text-color: #FFFFFF;

  --transparent-btn-bg-color: #FFFFFF;
  --transparent-btn-border-color: #FFFFFF;
  --transparent-btn-text-color: #FFFFFF;
  --transparent-btn-hover-bg-color: #29304E;
  --transparent-btn-hover-border-color: #29304E;
  --transparent-btn-hover-text-color: #FFFFFF;

  --accent-one-btn-bg-color: #005047;
  --accent-one-btn-border-color: #005047;
  --accent-one-btn-text-color: #ECE7D9;
  --accent-one-btn-hover-bg-color: #ECE7D9;
  --accent-one-btn-hover-border-color: #005047;
  --accent-one-btn-hover-text-color: #005047;

  --accent-two-btn-bg-color: #ECE7D9;
  --accent-two-btn-border-color: #ECE7D9;
  --accent-two-btn-text-color: #005047;
  --accent-two-btn-hover-bg-color: #005047;
  --accent-two-btn-hover-border-color: #ECE7D9;
  --accent-two-btn-hover-text-color: #ECE7D9;

  --accent-three-btn-bg-color: #ECE7D9;
  --accent-three-btn-border-color: #ECE7D9;
  --accent-three-btn-text-color: #29304E;
  --accent-three-btn-hover-bg-color: #852B2E;
  --accent-three-btn-hover-border-color: #852B2E;
  --accent-three-btn-hover-text-color: #ECE7D9;

  /* Breakpoints */
  --xs: 480px;
  --sm: 640px;
  --md: 768px;
  --lg: 1024px;
  --xl: 1280px;
  --2xl: 1536px;

  /* Constants */
  --menu-height: 3.75rem;
  --secondary-menu-height: 2rem;
  --header-height: calc(var(--menu-height) + var(--secondary-menu-height));
  --promobar-height: 2rem;
  --sidebar-width: 23.5rem;
  --product-image-aspect-ratio: 4 / 5;
  --content-max-lg-width: 96rem;
  --content-max-xl-width: 125.5rem;
}

@layer base {
  * {
    box-sizing: border-box;
  }

  html {
    @apply flex min-h-full w-full flex-col scroll-auto font-sans;
  }

  body {
    @apply text-body m-0 flex min-h-[var(--viewport-height)] w-full flex-col bg-background text-text;
  }

  #__next {
    @apply flex min-h-[var(--viewport-height)] flex-col;
  }

  main {
    @apply flex-1
  }

  h1 {
    @apply text-title-h1;
  }

  h2 {
    @apply text-title-h2;
  }

  h3 {
    @apply text-title-h3;
  }

  h4 {
    @apply text-title-h4;
  }

  h5 {
    @apply text-title-h5;
  }

  h6 {
    @apply text-title-h6;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type=number] {
    -moz-appearance: textfield;
  }
}

@layer components {
  /* text specific */
  .text-title-h1 {
    @apply
      font-sans
      text-4xl
      font-medium
      xl:text-6xl
      overflow-anywhere
      uppercase
  }
  .text-title-h2 {
    @apply
      font-sans
      text-3xl
      font-medium
      xl:text-5xl
      overflow-anywhere
  }
  .text-title-h3 {
    @apply
      font-sans
      text-2xl
      font-medium
      xl:text-4xl
      overflow-anywhere
  }
  .text-title-h4 {
    @apply
      font-sans
      text-xl
      font-medium
      xl:text-3xl
      overflow-anywhere
  }
  .text-title-h5 {
    @apply
      font-sans
      text-lg
      font-medium
      xl:text-2xl
      overflow-anywhere
  }
  .text-title-h6 {
    @apply
      font-sans
      text-lg
      font-light
      xl:text-xl
      overflow-anywhere
  }
  .text-body {
    @apply
      font-sans
      text-base
      font-normal
  }
  .text-body-sm {
    @apply
      font-sans
      text-sm
      font-normal
  }
  .text-body-lg {
    @apply
      font-sans
      text-lg
      font-normal
  }
  .text-hero {
    @apply
      font-sans
      text-5xl
      font-bold
      md:text-9xl
      overflow-anywhere
      uppercase
  }
  .text-link {
    @apply
      text-body
      font-bold
      uppercase
      underline
      tracking-widest
  }
  .text-link-sm {
    @apply
      text-link
      text-xs
      underline-offset-4
  }
  .text-caption {
    @apply
      font-sans
      font-normal
      text-sm
      uppercase
  }
  .text-label {
    @apply
      !font-sans
      !font-light
      !text-base
      !tracking-wide
      !uppercase
  }
  .text-label-sm {
    @apply
      font-sans
      font-light
      text-sm
      tracking-wide
      uppercase
  }
  .text-nav {
    @apply
      font-sans
      font-demi
      text-lg
      uppercase
      tracking-widest
  }
  .text-superheading {
    @apply
      text-title-h4
      font-normal
      uppercase
  }
  /* button specific */
  .btn-text {
    @apply
      font-sans
      font-bold
      uppercase
      text-base
      tracking-wider
  }
  .btn {
    @apply
      btn-text
      relative
      m-0
      inline-flex
      items-center
      justify-center
      overflow-hidden
      whitespace-nowrap
      border
      border-solid
      px-5
      py-3
      text-center
      transition-colors
      disabled:cursor-not-allowed
      disabled:border-border
      disabled:bg-lightGray
      disabled:text-mediumGray
      disabled:hover:border-border
      disabled:hover:bg-lightGray
      disabled:hover:text-mediumGray
      [&>p]:truncate
  }
  .btn-primary {
    @apply
      btn
      border-[var(--primary-btn-border-color)]
      bg-[var(--primary-btn-bg-color)]
      text-[var(--primary-btn-text-color)]
      md:hover:border-[var(--primary-btn-hover-border-color)]
      md:hover:bg-[var(--primary-btn-hover-bg-color)]
      md:hover:text-[var(--primary-btn-hover-text-color)]
  }
  .btn-secondary {
    @apply
      btn
      border-[var(--secondary-btn-border-color)]
      bg-[var(--secondary-btn-bg-color)]
      text-[var(--secondary-btn-text-color)]
      md:hover:border-[var(--secondary-btn-hover-border-color)]
      md:hover:bg-[var(--secondary-btn-hover-bg-color)]
      md:hover:text-[var(--secondary-btn-hover-text-color)]
  }
  .btn-inverse-light {
    @apply
      btn
      border-[var(--inverse-light-btn-border-color)]
      bg-[var(--inverse-light-btn-bg-color)]
      text-[var(--inverse-light-btn-text-color)]
      md:hover:border-[var(--inverse-light-btn-hover-border-color)]
      md:hover:bg-[var(--inverse-light-btn-hover-bg-color)]
      md:hover:text-[var(--inverse-light-btn-hover-text-color)]
  }
  .btn-inverse-dark {
    @apply
      btn
      border-[var(--inverse-dark-btn-border-color)]
      bg-[var(--inverse-dark-btn-bg-color)]
      text-[var(--inverse-dark-btn-text-color)]
      md:hover:border-[var(--inverse-dark-btn-hover-border-color)]
      md:hover:bg-[var(--inverse-dark-btn-hover-bg-color)]
      md:hover:text-[var(--inverse-dark-btn-hover-text-color)]
  }
  .btn-transparent {
    @apply
      btn
      border-[var(--transparent-btn-border-color)]
      bg-[rgba(255,255,255,0.5)]
      /* bg-[var(--transparent-btn-bg-color)]-500/50 */
      text-[var(--transparent-btn-text-color)]
      md:hover:border-[var(--transparent-btn-hover-border-color)]
      md:hover:bg-[var(--transparent-btn-hover-bg-color)]
      md:hover:text-[var(--transparent-btn-hover-text-color)]
  }
  .btn-atc {
    @apply
      btn
      border-[var(--primary-btn-border-color)]
      bg-[var(--primary-btn-bg-color)]
      text-[var(--primary-btn-text-color)]
      md:hover:border-[var(--primary-btn-hover-border-color)]
      md:hover:bg-[var(--primary-btn-hover-bg-color)]
      md:hover:text-[var(--primary-btn-hover-text-color)]
  }
  .btn-atc-select {
    @apply
    btn
    !border-[var(--inverse-dark-btn-border-color)]
    !bg-[var(--inverse-dark-btn-bg-color)]
    !text-[var(--inverse-dark-btn-text-color)]
    md:hover:border-[var(--inverse-dark-btn-hover-border-color)]
    md:hover:bg-[var(--inverse-dark-btn-hover-bg-color)]
    md:hover:text-[var(--inverse-dark-btn-hover-text-color)]
  }
  .btn-accent-one {
    @apply
      btn
      border-[var(--accent-one-btn-border-color)]
      bg-[var(--accent-one-btn-bg-color)]
      text-[var(--accent-one-btn-text-color)]
      md:hover:border-[var(--accent-one-btn-hover-border-color)]
      md:hover:bg-[var(--accent-one-btn-hover-bg-color)]
      md:hover:text-[var(--accent-one-btn-hover-text-color)]
  }
  .btn-accent-two {
    @apply
      btn
      border-[var(--accent-two-btn-border-color)]
      bg-[var(--accent-two-btn-bg-color)]
      text-[var(--accent-two-btn-text-color)]
      md:hover:border-[var(--accent-two-btn-hover-border-color)]
      md:hover:bg-[var(--accent-two-btn-hover-bg-color)]
      md:hover:text-[var(--accent-two-btn-hover-text-color)]
  }
  .btn-accent-three {
    @apply
      btn
      border-[var(--accent-three-btn-border-color)]
      bg-[var(--accent-three-btn-bg-color)]
      text-[var(--accent-three-btn-text-color)]
      md:hover:border-[var(--accent-three-btn-hover-border-color)]
      md:hover:bg-[var(--accent-three-btn-hover-bg-color)]
      md:hover:text-[var(--accent-three-btn-hover-text-color)]
  }
  .btn-text-sm {
    @apply
      font-sans
      font-light
      uppercase
      text-xs
      tracking-wider
  }
  .btn-sm {
    @apply
      btn-text-sm
      relative
      m-0
      inline-flex
      items-center
      justify-center
      overflow-hidden
      whitespace-nowrap
      border
      border-solid
      px-2
      py-1
      text-center
      transition-colors
      disabled:cursor-not-allowed
      disabled:border-border
      disabled:bg-lightGray
      disabled:text-mediumGray
      disabled:hover:border-border
      disabled:hover:bg-lightGray
      disabled:hover:text-mediumGray
      [&>p]:truncate
  }
  .btn-sm-primary {
    @apply
      btn-sm
      border-[var(--primary-btn-border-color)]
      bg-[var(--primary-btn-bg-color)]
      text-[var(--primary-btn-text-color)]
      md:hover:border-[var(--primary-btn-hover-border-color)]
      md:hover:bg-[var(--primary-btn-hover-bg-color)]
      md:hover:text-[var(--primary-btn-hover-text-color)]
  }
  .btn-sm-secondary {
    @apply
      btn-sm
      border-[var(--secondary-btn-border-color)]
      bg-[var(--secondary-btn-bg-color)]
      text-[var(--secondary-btn-text-color)]
      md:hover:border-[var(--secondary-btn-hover-border-color)]
      md:hover:bg-[var(--secondary-btn-hover-bg-color)]
      md:hover:text-[var(--secondary-btn-hover-text-color)]
  }
  .btn-sm-inverse-light {
    @apply
      btn-sm
      border-[var(--inverse-light-btn-border-color)]
      bg-[var(--inverse-light-btn-bg-color)]
      text-[var(--inverse-light-btn-text-color)]
      md:hover:border-[var(--inverse-light-btn-hover-border-color)]
      md:hover:bg-[var(--inverse-light-btn-hover-bg-color)]
      md:hover:text-[var(--inverse-light-btn-hover-text-color)]
  }
  .btn-sm-inverse-dark {
    @apply
      btn-sm
      border-[var(--inverse-dark-btn-border-color)]
      bg-[var(--inverse-dark-btn-bg-color)]
      text-[var(--inverse-dark-btn-text-color)]
      md:hover:border-[var(--inverse-dark-btn-hover-border-color)]
      md:hover:bg-[var(--inverse-dark-btn-hover-bg-color)]
      md:hover:text-[var(--inverse-dark-btn-hover-text-color)]
  }
  .btn-sm-transparent {
    @apply
      btn-sm
      border-transparent
      bg-transparent
      text-[var(--primary-btn-text-color)]
      md:hover:border-transparent
      md:hover:bg-transparent
      md:hover:text-[var(--primary-btn-text-color)]
  }
  /* underline specific */
  .hover-text-underline {
    @apply
      relative
      inline
      bg-[linear-gradient(currentColor,currentColor)]
      bg-[length:0%_1px]
      bg-left-bottom
      bg-no-repeat
      transition-[background-size]
      duration-200
      focus:bg-[length:100%_1px]
      md:hover:bg-[length:100%_1px]
  }
  .text-underline {
    @apply
      relative
      inline
      bg-[linear-gradient(currentColor,currentColor)]
      bg-[length:100%_1px]
      bg-right-bottom
      bg-no-repeat
      transition-[background-size]
      duration-200
      focus:bg-[length:0%_1px]
      md:hover:bg-[length:0%_1px]
  }
  .text-main-underline {
    @apply
      relative
      inline
      bg-[linear-gradient(currentColor,currentColor)]
      bg-[length:100%_0.5px]
      bg-right-bottom
      bg-no-repeat
      pb-px
      transition-[background-size]
      duration-200
      focus:bg-[length:0%_0.5px]
      md:hover:bg-[length:0%_0.5px]
  }
  /* inputs specific */
  .input-text {
    @apply
      h-12
      w-full
      shadow-hairline
      p-2.5
      text-base
  }
  .input-label {
    @apply
      block
      pb-1
      font-sans
      text-sm
      font-bold
  }
  /* swiper specific */
  .swiper-wrapper-center .swiper-wrapper {
    @apply
      flex
      items-center
  }
  .swiper-offset-gradient-270-left {
    background: linear-gradient(270deg, var(--background) 0%, rgba(255, 255, 255, 0) 100%);
    @apply pointer-events-none absolute top-0 left-0 bottom-0 z-10 h-full w-[5.9375rem] -rotate-180 opacity-[60] lg:w-[8.125rem]
  }
  .swiper-offset-gradient-270-right {
    background: linear-gradient(270deg, var(--background) 0%, rgba(255, 255, 255, 0) 100%);
    @apply pointer-events-none absolute top-0 right-0 bottom-0 z-10 h-full w-[5.9375rem] opacity-[60] lg:w-[8.125rem]
  }
  .active-bullet-black .swiper-pagination-bullet-active {
    @apply
      bg-primary
  }
  .swift-up-text-link {
    span {
      @apply
        underline
    }
  }
}

@layer utilities {
  /* hide scrollbar */
  .scrollbar-hide {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .scrollbar-hide::-webkit-scrollbar {
    display: none;
  }
  /* horizontal padding */
  .px-contained {
    @apply
      px-5
      xl:px-10
  }
  .px-contained-sm {
    @apply
      px-5
  }
  /* vertical padding */
  .py-contained {
    @apply
      py-5
      xl:py-10
  }
  .py-contained-sm {
    @apply
      py-5
  }
  /* extras */
  .overflow-anywhere {
    overflow-wrap: anywhere;
  }
  .shadow-hairline {
    box-shadow: 0 0 0 .5px var(--border);
  }
  .shadow-hairline-b {
    box-shadow: 0 .5px var(--border);
  }
  .shadow-hairline-r {
    box-shadow: .5px 0 var(--border);
  }
  .shadow-hairline-primary {
    box-shadow: 0 0 0 .5px var(--primary);
  }
  .filter-panel.is-stuck {
    + .collection-container .filter-sidebar {
      @apply
        md:pt-10
    }
    > div:nth-child(2) {
      > div {
        > div:nth-child(3) {
          top: 1px;
          > div > ul {
            border-color: var(--off-white);
          }
        } 
        @apply
          md:items-center
      }
    }
  }
}

